import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

interface PageProps {
  data: {
    page: {
      title?: string
      path?: string
      seo: any
      flexContent: any
      locale: any
      translations: any
    }
  }
  location?: any
}

const PageTemplate: React.FC<PageProps> = ({
  data: {
    page: { flexContent, seo, locale, translations },
  },
  location = {},
}) => (
  <Layout locale={locale.locale} translations={translations}>
    <SEO seo={seo} />
    <FlexContentHandler
      prefix="InternalJob_Flexcontent"
      fields={flexContent}
      location={location}
      intern={true}
    />
  </Layout>
)

export const pageQuery = graphql`
  query interalJobById($id: String!) {
    page: wpInternalJob(id: { eq: $id }) {
      ...generalInternalJobFragment
    }
  }
`

export default PageTemplate
